@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

html {
    box-sizing: border-box;
    font-size: 100%;
    scroll-behavior: smooth;
}

/* Resets */

*,
*::after,
*::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
p {
    margin-top: 0;
}

h1,
h2 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

a,
a:visited,
a:active {
    text-decoration: none;
}

ul,
li {
    list-style: none;
}

img,
picture,
svg {
    max-width: 100%;
    display: block;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgb(235, 235, 235);
}

::-webkit-scrollbar-thumb {
    background: #61a83e;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #45722f;
    border-radius: 50px;
}

#root {
    width: 100%;
}

/* Body */
body {
    font-family: 'Poppins', sans-serif;
    textRendering: optimizeSpeed;
    display: grid;
    place-items: center;
    overflow-x: hidden;
}

/* Header */
.header_container {
    width: 100%;
}

.contact_top-container {
    width: 100%;
    background-color: rgb(23, 88, 39);
}

.contact_top-content {
    max-width: 1440px;
    display: flex;
    gap: 2rem;
    margin: 0 auto;
    padding: 0 2.2rem;
}

.email_top,
.phone_top {
    display: flex;
    gap: .5rem;
    align-items: center;
    color: rgb(248, 248, 248);
    font-style: italic;
}

.phone_top a {
    color: rgb(248, 248, 248);
}

.wrapper {
    position: fixed;
    top: 23px;
    width: 100%;
    background-color: rgba(250, 250, 250, 0.8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    transition: .5s ease;
    z-index: 2;
}

.nav_container {
    max-width: 1440px;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 2rem;
}

.wrapper.active {
    background-color: rgba(250, 250, 250, .9);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.nav_lists {
    display: flex;
    align-items: center;
}

.nav_link {
    color: rgb(49, 49, 49);
    border-radius: 50px;
    transition: .4s ease;
    font-weight: 500;
}

.nav_list-item {
    height: 6rem;
    display: flex;
    align-items: center;
    gap: .8rem;
    cursor: pointer;
    transition: .4s ease;
    padding: 0 .8rem;
}

.wrapper.active .nav_list-item {
    height: 4.2rem;
}

.nav_list-item:hover {
    background-color: #61a83e;
}

.nav_list-item svg {
    transition: .4s ease;
}

@media screen and (max-width: 900px) {
    .nav_list-item .icon {
        display: none;
    }
}

.nav_list-item:hover .nav_link,
.nav_list-item:hover svg {
    color: white;
    fill: white;
}

.nav_list-item svg {
    display: block;
    width: 40px;
    fill: rgb(49, 49, 49);
}

.nav_list-item .home-icon {
    width: 45px;
}

.nav_list-item .about-icon {
    width: 35px;
}

.nav_list-item .product-icon {
    width: 40px;
}

.logo_container img {
    transition: .4s ease;
    max-width: 260px;
}

/* Hero section */
.hero_section {
    width: 100%;
    height: 90vh;
    max-height: 1000px;
}

.hero_container {
    position: relative;
    background-image: url("./images/hero-image4.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    animation: chbg 5s infinite alternate;
    animation-delay: -30s;
    width: 100%;
    height: 100%;
}

@keyframes chbg {
    100% {
        background-image: url("./images/hero-image.png");
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    }
}

.hero_content-container {
    position: relative;
    top: 35%;
    max-width: 1440px;
    margin: 0 auto;
    width: 40rem;
    padding: 2.5rem;
    color: white;
    background-color: rgba(97, 168, 62, 0.9);
    background-clip: padding-box;
    border: 10px solid hsla(0, 0%, 100%, .5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 5px;
}

.hero_heading {
    margin-bottom: 1rem;
    letter-spacing: 2px;
    font-size: 2rem;
}

.about {
    height: 1000px;
}